import React from 'react';
import dynamic from 'next/dynamic';

import { GetStartedWidgetProps } from '../GetStartedWidget';
const LegacyEmailWidget = dynamic(() =>
  import('../LegacyEmailWidget').then((mod) => mod.LegacyEmailWidget)
);
const LegacyPhoneNumberWidget = dynamic(() =>
  import('../LegacyPhoneNumberWidget').then(
    (mod) => mod.LegacyPhoneNumberWidget
  )
);
const LegacyGetStartedWidget = dynamic(() =>
  import('../LegacyGetStartedWidget').then((mod) => mod.LegacyGetStartedWidget)
);
const DesktopPhoneNumberWidget = dynamic(() =>
  import('../DesktopPhoneNumberWidget').then(
    (mod) => mod.DesktopPhoneNumberWidget
  )
);
const MobilePhoneNumberWidget = dynamic(() =>
  import('../MobilePhoneNumberWidget').then(
    (mod) => mod.MobilePhoneNumberWidget
  )
);
const RoleSelectWebregWidget = dynamic(() =>
  import('../RoleSelectWidgetWebreg').then((mod) => mod.RoleSelectWebregWidget)
);
const GetStartedNoPhoneWidget = dynamic(() =>
  import('../GetStartedNoPhoneWidget').then(
    (mod) => mod.GetStartedNoPhoneWidget
  )
);
const GetStartedWidget = dynamic(() =>
  import('../GetStartedWidget').then((mod) => mod.GetStartedWidget)
);

const EmailWidget = dynamic(() =>
  import('../EmailWidget').then((mod) => mod.EmailWidget)
);
const StandardWidget = dynamic(() =>
  import('../StandardWidget').then((mod) => mod.StandardWidget)
);
const CognitoAuthWidget = dynamic(() =>
  import('../CognitoAuthWidget').then((mod) => mod.CognitoAuthWidget)
);
const InlineAuthWidget = dynamic(() =>
  import('../InlineAuthWidget').then((mod) => mod.InlineAuthWidget)
);

import { WidgetProps, ctaWidgetEnum, SharedProps } from '../shared';

import { InBlockPhoneWidget } from '../styles';

type CtaWidgetProps = GetStartedWidgetProps | (WidgetProps & SharedProps);

export function CtaWidgetMap({
  designType,
  ...props
}: CtaWidgetProps & { designType: ctaWidgetEnum }) {
  switch (designType) {
    case ctaWidgetEnum.DESKTOP_PHONE_NUMBER_V1:
      return <LegacyPhoneNumberWidget {...props} />;

    case ctaWidgetEnum.INBLOCK_PHONE_NUMBER_V1:
      return <InBlockPhoneWidget {...props} />;

    case ctaWidgetEnum.GET_STARTED_V1:
      return <LegacyGetStartedWidget {...props} />;

    case ctaWidgetEnum.EMAIL_V1:
      return <LegacyEmailWidget {...props} />;

    case ctaWidgetEnum.DESKTOP_PHONE_NUMBER:
      return <DesktopPhoneNumberWidget {...(props as WidgetProps)} />;

    case ctaWidgetEnum.MOBILE_PHONE_NUMBER:
      return <MobilePhoneNumberWidget {...(props as WidgetProps)} />;

    case ctaWidgetEnum.MOBILE_PHONE_NUMBER_DROPSHADOW:
      return (
        <MobilePhoneNumberWidget
          {...(props as WidgetProps)}
          useDropshadow={true}
        />
      );

    case ctaWidgetEnum.GET_STARTED_NO_PHONE:
      return <GetStartedNoPhoneWidget {...props} />;

    case ctaWidgetEnum.EMAIL:
      return <EmailWidget {...props} />;

    case ctaWidgetEnum.GET_STARTED:
      return <GetStartedWidget {...(props as GetStartedWidgetProps)} />;

    case ctaWidgetEnum.STANDARD:
      return <StandardWidget {...props} />;

    case ctaWidgetEnum.ROLE_SELECT_WEBREG:
      return <RoleSelectWebregWidget {...(props as WidgetProps)} />;

    case ctaWidgetEnum.GL4C_AUTH:
      return <CognitoAuthWidget {...props} />;

    case ctaWidgetEnum.GL4C_INLINE_AUTH:
      return <InlineAuthWidget {...props} />;

    default:
      return null;
  }
}
