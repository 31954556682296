import React from 'react';

import { IPageFields } from '../../../types/contentful';

import { usePageDataContext } from '../../context/PageData';

import { FinalCallout } from '../FinalCallout';

import { VariationContainer } from '../VariationContainer';

export function Callouts({ finalCtaType }: IPageFields) {
  const { config: { finalCta, hideFinalCta } = {} } = usePageDataContext();

  // If global config set to true then hide final CTA
  if (hideFinalCta) {
    return null;
  }

  // Use CTA config from page else fallback to global
  const { sys, fields }: any = finalCtaType ?? finalCta ?? {};

  // Return final CTA variations
  switch (sys?.contentType?.sys?.id) {
    case 'finalCallout':
      return <FinalCallout sysId={sys.id} {...fields} />;

    case 'variationContainer':
      // Do not lazy load
      return <VariationContainer {...fields} sysId={sys?.id} />;

    default:
      return null;
  }
}
