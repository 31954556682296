import React from 'react';
import {
  RenderNode,
  RenderMark,
  Options,
  documentToReactComponents as renderRichText
} from '@contentful/rich-text-react-renderer';
import { BLOCKS, MARKS, INLINES, Document } from '@contentful/rich-text-types';

import { ColorTokenProp } from '../../theme/tokens/color';

import { BulletColor } from './Nodes/shared';

import { Text } from '../Text';

import {
  ParagraphNode,
  UnorderedListNode,
  OrderedListNode,
  ListItemNode,
  HeadingNode,
  EmbeddedEntryNode,
  HyperlinkNode,
  EmbeddedInlineEntryNode,
  BlockquoteNode,
  AssetNode,
  HorizontalRuleNode,
  SubheadingNode
} from './Nodes';

export interface RichTextProps {
  data?: Document | null;
  colour?: ColorTokenProp | string;
  bulletColor?: BulletColor;
  overrideOptions?: RenderNode;
  overrideMarkOptions?: RenderMark;
}

export default function RichText({
  data,
  colour = 'defaultText',
  bulletColor = 'green',
  overrideOptions,
  overrideMarkOptions
}: RichTextProps) {
  const options: Options = {
    renderMark: {
      [MARKS.BOLD]: (text) => (
        <Text as="span" fontWeight="500">
          {text}
        </Text>
      ),
      [MARKS.CODE]: (children) => <sup>{children}</sup>,
      ...overrideMarkOptions
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <ParagraphNode node={node} colour={colour}>
          {children}
        </ParagraphNode>
      ),
      [BLOCKS.UL_LIST]: (node, children) => (
        <UnorderedListNode bulletColor={bulletColor} node={node}>
          {children}
        </UnorderedListNode>
      ),
      [BLOCKS.OL_LIST]: (node, children) => (
        <OrderedListNode node={node}>{children}</OrderedListNode>
      ),
      [BLOCKS.LIST_ITEM]: (node, children) => (
        <ListItemNode node={node}>{children}</ListItemNode>
      ),
      [BLOCKS.HEADING_1]: (node, children) => (
        <HeadingNode type="h1" node={node}>
          {children}
        </HeadingNode>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <HeadingNode type="h2" node={node}>
          {children}
        </HeadingNode>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <HeadingNode type="h3" node={node}>
          {children}
        </HeadingNode>
      ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <SubheadingNode type="h4" node={node}>
          {children}
        </SubheadingNode>
      ),
      [BLOCKS.HEADING_5]: (node, children) => (
        <SubheadingNode type="h5" node={node}>
          {children}
        </SubheadingNode>
      ),
      [BLOCKS.HEADING_6]: (node, children) => (
        <SubheadingNode type="h6" node={node}>
          {children}
        </SubheadingNode>
      ),
      [BLOCKS.EMBEDDED_ENTRY]: (node, children) => (
        <EmbeddedEntryNode node={node} colour={colour}>
          {children}
        </EmbeddedEntryNode>
      ),
      [INLINES.HYPERLINK]: (node, children) => (
        <HyperlinkNode node={node} colour={colour}>
          {children}
        </HyperlinkNode>
      ),
      [INLINES.EMBEDDED_ENTRY]: (node, children) => (
        <EmbeddedInlineEntryNode node={node} colour={colour}>
          {children}
        </EmbeddedInlineEntryNode>
      ),
      [BLOCKS.QUOTE]: (node, children) => (
        <BlockquoteNode node={node}>{children}</BlockquoteNode>
      ),
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => (
        <AssetNode node={node}>{children}</AssetNode>
      ),
      [BLOCKS.HR]: (node, children) => (
        <HorizontalRuleNode node={node}>{children}</HorizontalRuleNode>
      ),
      ...overrideOptions
    }
  };

  return data ? (renderRichText(data, options) as React.ReactElement) : null;
}
