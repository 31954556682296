import { usePageDataContext } from '~/context/PageData';
import Blocks from '../Page/Blocks';

export interface VariationProps {
  className?: string;
  isActive?: boolean;
}

export function Variation({ pageBlock, pageBlocks, className, isActive }: any) {
  const { sys, fields } = pageBlock ?? {};
  const { page } = usePageDataContext();

  if (!isActive) {
    return null;
  }

  return (
    <div className={className}>
      <Blocks
        sys={sys}
        fields={fields}
        isActive={isActive}
        seoProps={{
          page,
          pageBlock
        }}
      />
      {pageBlocks?.map((block: any) => (
        <section
          key={block.sys?.id}
          data-cfid={block.sys?.id}
          data-testid="page-block"
        >
          <Blocks
            sys={block.sys}
            fields={block.fields}
            isActive={isActive}
            seoProps={{
              page,
              block
            }}
          />
        </section>
      ))}
    </div>
  );
}
