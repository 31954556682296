import React from 'react';
import {
  IExitIntent,
  IExitIntentFields,
  IVariationContainer,
  IVariationContainerFields
} from '../../../types/contentful';
import { ExitIntent } from '../ExitIntent';
import { VariationContainer } from '../VariationContainer';

export function ExitIntentContainer({
  sys,
  fields
}: IExitIntent | IVariationContainer) {
  switch (sys.contentType.sys.id) {
    case 'exitIntent':
      return <ExitIntent {...(fields as IExitIntentFields)} />;
    case 'variationContainer':
      return (
        <VariationContainer
          sysId={sys.id}
          {...(fields as IVariationContainerFields)}
        />
      );
  }
}
