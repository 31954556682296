import React from 'react';
import Link from 'next/link';

import { IFooter20Fields } from '../../../../../types/contentful';

import glFullLogo from '../../../../../public/images/gl-logo-full.svg';
import GlImage from '../../../Image';

export function FooterImage({
  glTagline,
  glLogo,
  isAffiliateFooter
}: IFooter20Fields) {
  const { file, description } = glLogo?.fields ?? {};

  return (
    <>
      {!isAffiliateFooter ? (
        <Link
          href="/"
          prefetch={false}
          className="relative h-[32px] w-[206px] tablet:h-[24px] tablet:w-[150px]"
        >
          <GlImage
            src={file?.url || glFullLogo}
            alt={description ?? 'Greenlight logo'}
            loading="lazy"
            layout="fill"
            objectFit="contain"
          />
        </Link>
      ) : (
        <span className="relative h-[32px] w-[206px] tablet:h-[24px] tablet:w-[150px]">
          <GlImage
            src={file?.url || glFullLogo}
            alt={description ?? 'Greenlight logo'}
            loading="lazy"
            layout="fill"
            objectFit="contain"
          />
        </span>
      )}
      <p className="mt-5 text-lg leading-5 text-white">{glTagline}</p>
    </>
  );
}
