import React from 'react';
import Blocks from './Blocks';
import { IPageFields } from '@/types/contentful';

export function PageBlocks({ pageBlocks }: IPageFields) {
  return (
    <>
      {pageBlocks?.map(({ sys, fields }: any) => (
        <section key={sys?.id} data-cfid={sys?.id} data-testid="page-block">
          <Blocks sys={sys} fields={fields} />
        </section>
      ))}
    </>
  );
}
