import { ILearningCenterBannerCtaFields } from '@/types/contentful';
import { RenderNode } from '@contentful/rich-text-react-renderer';
import { Block, BLOCKS, Inline } from '@contentful/rich-text-types';
import React from 'react';
import RichText from '~/components/RichText';
import { useOptimizely } from 'src/context/Optimizely';
import { usePublicRuntimeConfig } from '~/hooks/usePublicRuntimeConfig';
import { normalizeHexCode } from '~/styles/utils';

export default function BannerCTA({
  logoImage,
  backgroundColor,
  header,
  bodyText,
  buttonText,
  registrationButton,
  buttonAnchor,
  buttonColor
}: ILearningCenterBannerCtaFields) {
  const experiments = useOptimizely();

  const { webregUrl: fallBackWebregUrl, onboardingUrl } =
    usePublicRuntimeConfig();

  const RICH_TEXT_OVERRIDE: RenderNode = {
    [BLOCKS.PARAGRAPH]: (_node: Block | Inline, children) => {
      return <p className="leading-6">{children}</p>;
    }
  };
  const webregURL = experiments?.website_onboarding_initialize?.enabled
    ? onboardingUrl
    : fallBackWebregUrl;
  return (
    <div
      className={`banner-cta`}
      style={{ backgroundColor: `${normalizeHexCode(backgroundColor)}` }}
    >
      <div className="banner-cta-logo-container">
        {logoImage && (
          <div className="banner-cta-logo">
            <img src={logoImage.fields.file.url} alt={logoImage.fields.title} />
          </div>
        )}
        <h2 className="banner-cta-title-mobile">{header}</h2>
      </div>
      <div className="banner-cta-content-container">
        <div className="banner-cta-content">
          <h2 className="banner-cta-title-desktop">{header}</h2>
          <div className="flex flex-1 flex-col items-start gap-1">
            <RichText data={bodyText} overrideOptions={RICH_TEXT_OVERRIDE} />
          </div>
        </div>
        <div className="banner-cta-button-container">
          <div
            className="banner-cta-button cursor-pointer"
            style={{ backgroundColor: `${normalizeHexCode(buttonColor)}` }}
          >
            <a
              type="button"
              href={registrationButton ? webregURL : buttonAnchor}
            >
              {buttonText}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
