import React from 'react';

import { ColorTokenProp } from '../../../theme/tokens/color';

import { ParagraphWithLineBreaks } from '../styles';

import { NodeProps } from './shared';

interface ParagraphNodeProps extends NodeProps {
  colour?: ColorTokenProp | string;
}

export function ParagraphNode({ colour, children }: ParagraphNodeProps) {
  return (
    <ParagraphWithLineBreaks
      as="p"
      fontSize={{ 0: 'p_0', desktop: 'p_desktop' }}
      colour={colour}
    >
      {children}
    </ParagraphWithLineBreaks>
  );
}
