import React from 'react';

import { IFooter20Fields } from '../../../../../types/contentful';

import { Layout, Column } from '../../../Layout';
import { Disclaimers } from './Disclaimers';
import { LegalNavLinks } from './LegalNavLinks';
import { PageFootnotes } from './PageFootnotes';

import { BrightEdgeColumn, LegalBlockContainer } from './styles';

export function Legal({
  legalLinks,
  pageFootnotes,
  isAffiliateFooter
}: IFooter20Fields) {
  return (
    <LegalBlockContainer
      backgroundColor="footerBgSecondary"
      revealOnScroll={false}
      data-blockid="legal-footer20"
      data-testid="legal-footer20"
    >
      <Layout id="legal-layout">
        <BrightEdgeColumn size={12}>
          {/* BrightEdge Autopilot */}
          <div className="be-ix-link-block" />
        </BrightEdgeColumn>
        <Column size={12} paddingTop="sm" paddingBottom="sm">
          <LegalNavLinks
            links={legalLinks}
            isAffiliateFooter={isAffiliateFooter}
          />
        </Column>
        {pageFootnotes && (
          <Column size={12} paddingTop="sm" paddingBottom="sm">
            <PageFootnotes {...pageFootnotes} />
          </Column>
        )}
        <Column
          size={12}
          flexDirection="column"
          paddingTop="sm"
          paddingBottom="sm"
        >
          <Disclaimers />
        </Column>
      </Layout>
    </LegalBlockContainer>
  );
}
